import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { DiabetesRiskDiagnosisResultsBlockItem } from '../../models/diabetes-risk-diagnosis-results.model';
import CardComponent from './card.component';

@Component({
    selector: 'big-service-tools-diabetes-diagnosis-offered-services',
    templateUrl: './offered-services.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CardComponent, TranslocoDirective],
})
export default class OfferedServicesComponent {
    @HostBinding('class') public classList = 'block';

    public riskBlockItem = input.required<DiabetesRiskDiagnosisResultsBlockItem>();
    public isOlderThan35 = input.required<boolean>();
    public isSmoker = input.required<boolean>();
    public hasHighBloodPressure = input.required<boolean>();
    public signedUpForDmp = input<boolean>(true);
    public isOlderThan50 = input<boolean>(false);

    public showBlock(block: string[], needles: string[]): boolean {
        return block.some(value => needles.includes(value));
    }
}
