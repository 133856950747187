import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { UiAlertComponent } from '@big-direkt/ui/alert';
import { UiTableCellDirective, UiTableComponent, UiTableRowDirective } from '@big-direkt/ui/table';
import { TableHeaderCellModel } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'big-service-tools-diabetes-diagnosis-bmi-table',
    templateUrl: './bmi-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DecimalPipe, TranslocoDirective, UiAlertComponent, UiTableCellDirective, UiTableComponent, UiTableRowDirective],
})
export default class BmiTableComponent {
    @HostBinding('class') public classList = 'block';

    public bmitTableHeader: TableHeaderCellModel[] = [
        {
            value: ' ',
        },
        {
            value: 'stDiabetesDiagnosis.bmiTable.bmi',
        },
        {
            value: 'stDiabetesDiagnosis.bmiTable.riskOfDiseases.headerTitle',
        },
    ];

    public bodyMassIndex = input.required<number>();

    /* eslint-disable @typescript-eslint/no-magic-numbers */
    public getBmiClassification(): string {
        const bmi = this.bodyMassIndex();

        if (bmi > 18.5 && bmi <= 24.9) {
            return 'bmiTable.normalWeight';
        } else if (bmi >= 25 && bmi <= 29.9) {
            return 'bmiTable.overWeight';
        } else if (bmi >= 30 && bmi <= 34.9) {
            return 'bmiTable.gradeObesity1';
        } else if (bmi >= 35 && bmi <= 39.9) {
            return 'bmiTable.gradeObesity2';
        } else if (bmi > 40) {
            return 'bmiTable.gradeObesity3';
        }

        return 'bmiTable.underWeight';
    }
    /* eslint-enable @typescript-eslint/no-magic-numbers */
}
