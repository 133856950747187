import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { IconBigMediumOk, IconBigMediumPfeilRechts, IconComponent } from '@big-direkt/utils/icons';
import { Link } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'big-service-tools-diabetes-diagnosis-card',
    templateUrl: './card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        IconComponent,
        TranslocoDirective,
    ],
})
export default class CardComponent {
    public readonly iconArrowRight = IconBigMediumPfeilRechts;
    public readonly iconCheck = IconBigMediumOk;

    @HostBinding('class') public classList = 'block';

    public title = input.required<string>();
    public link = input<Link>();
}
