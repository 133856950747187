<ng-container *transloco="let t; prefix: 'stDiabetesDiagnosis'">
    <h2 class="mt-10 mb-2 text-2xl">{{ t('offeredServices.supportByBig') }}</h2>

    @if (
        showBlock(riskBlockItem().healthCheck, ['doctor_search', 'medical_appointment_service', 'family_doctor_care']) || isOlderThan35() || !signedUpForDmp()
    ) {
        <h2 class="mt-6 mb-2 text-xl">{{ t('offeredServices.servicesBigTitle') }}</h2>
        <p class="mb-6">{{ t('offeredServices.servicesBigDescription') }}</p>

        <div class="space-y-4">
            @if (showBlock(riskBlockItem().healthCheck, ['doctor_search'])) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.doctorSearch.title')"
                    [link]="{ href: t('card.doctorSearch.link') }">
                    <p>{{ t('card.doctorSearch.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }

            @if (showBlock(riskBlockItem().healthCheck, ['dmp']) && !signedUpForDmp()) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.dmp.title')"
                    [link]="{ href: t('card.dmp.link') }">
                    <p>{{ t('card.dmp.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }

            @if (showBlock(riskBlockItem().healthCheck, ['medical_appointment_service'])) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.medicalAppointmentService.title')"
                    [link]="{ href: t('card.medicalAppointmentService.link') }">
                    <p>{{ t('card.medicalAppointmentService.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }

            @if (showBlock(riskBlockItem().healthCheck, ['family_doctor_care'])) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.familyDoctorCare.title')"
                    [link]="{ href: t('card.familyDoctorCare.link') }">
                    <p>{{ t('card.familyDoctorCare.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }

            @if (showBlock(riskBlockItem().healthCheck, ['family_doctor_search'])) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.familyDoctorSearch.title')"
                    [link]="{ href: t('card.familyDoctorSearch.link') }">
                    <p>{{ t('card.familyDoctorSearch.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }

            @if (showBlock(riskBlockItem().healthCheck, ['health_check_up_35']) && isOlderThan35()) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.healthCheckUp35.title')"
                    [link]="{ href: t('card.healthCheckUp35.link') }">
                    <p>{{ t('card.healthCheckUp35.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }

            @if (hasHighBloodPressure() && showBlock(riskBlockItem().healthCheck, ['hypertension_consequences'])) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.hypertensionConsequences.title')"
                    [link]="{ href: t('card.hypertensionConsequences.link') }">
                    <p>{{ t('card.hypertensionConsequences.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }
        </div>
    }

    @if (showBlock(riskBlockItem().treatmentServices, ['digital_health_application', 'bigmedcoach']) || isSmoker()) {
        <h2 class="mt-10 mb-2 text-xl">{{ t('offeredServices.treatmentServicesTitle') }}</h2>
        <p class="mb-6">{{ t('offeredServices.treatmentServicesDescription') }}</p>

        <div class="space-y-4">
            @if (showBlock(riskBlockItem().treatmentServices, ['bigmedcoach'])) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.bigmedcoach.title')"
                    [link]="{ href: t('card.bigmedcoach.link') }">
                    <p>{{ t('card.bigmedcoach.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }

            @if (showBlock(riskBlockItem().treatmentServices, ['digital_health_application'])) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.digitalHealthApplication.title')"
                    [link]="{ href: t('card.digitalHealthApplication.link') }">
                    <p>{{ t('card.digitalHealthApplication.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }

            @if (showBlock(riskBlockItem().treatmentServices, ['provision_of_aids'])) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.provisionOfAids.title')"
                    [link]="{ href: t('card.provisionOfAids.link') }">
                    <p>{{ t('card.provisionOfAids.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }

            @if (isSmoker()) {
                @if (showBlock(riskBlockItem().treatmentServices, ['prevention_services_quit_smoking'])) {
                    <big-service-tools-diabetes-diagnosis-card
                        [title]="t('card.preventionServicesQuitSmoking.title')"
                        [link]="{ href: t('card.preventionServicesQuitSmoking.link') }">
                        <p>{{ t('card.preventionServicesQuitSmoking.description') }}</p>
                    </big-service-tools-diabetes-diagnosis-card>
                }

                @if (showBlock(riskBlockItem().treatmentServices, ['app_quit_smoking'])) {
                    <big-service-tools-diabetes-diagnosis-card
                        [title]="t('card.appQuitSmoking.title')"
                        [link]="{ href: t('card.appQuitSmoking.link') }">
                        <p>{{ t('card.appQuitSmoking.description') }}</p>
                    </big-service-tools-diabetes-diagnosis-card>
                }
            }
        </div>
    }

    @if (riskBlockItem().vaccinations?.length) {
        <h2 class="mt-10 mb-2 text-xl">{{ t('offeredServices.vaccinationServicesTitle') }}</h2>
        <p class="mb-6">{{ t('offeredServices.vaccinationServicesDescription') }}</p>

        <div class="space-y-4">
            @if (showBlock(riskBlockItem().vaccinations ?? [], ['herpes']) && isOlderThan50()) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.herpes.title')"
                    [link]="{ href: t('card.herpes.link') }"></big-service-tools-diabetes-diagnosis-card>
            }

            @if (showBlock(riskBlockItem().vaccinations ?? [], ['influenza'])) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.influenza.title')"
                    [link]="{ href: t('card.influenza.link') }"></big-service-tools-diabetes-diagnosis-card>
            }

            @if (showBlock(riskBlockItem().vaccinations ?? [], ['pneumococcal']) && isOlderThan50()) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.pneumococcal.title')"
                    [link]="{ href: t('card.pneumococcal.link') }"></big-service-tools-diabetes-diagnosis-card>
            }
        </div>
    }

    @if (riskBlockItem().preventionServices.length) {
        <h2 class="mt-10 mb-2 text-xl">{{ t('offeredServices.preventionServicesTitle') }}</h2>
        <p class="mb-6">{{ t('offeredServices.preventionServicesDescription') }}</p>

        <div class="space-y-4">
            @if (showBlock(riskBlockItem().preventionServices, ['prevention_courses'])) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.preventionCourses.title')"
                    [link]="{ href: t('card.preventionCourses.link') }">
                    <p>{{ t('card.preventionCourses.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }

            @if (showBlock(riskBlockItem().preventionServices, ['bigtionaer'])) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.bigtionaer.title')"
                    [link]="{ href: t('card.bigtionaer.link') }">
                    <p>{{ t('card.bigtionaer.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }

            @if (showBlock(riskBlockItem().preventionServices, ['sports_medical_check_up'])) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.sportsMedicalCheckUp.title')"
                    [link]="{ href: t('card.sportsMedicalCheckUp.link') }">
                    <p>{{ t('card.sportsMedicalCheckUp.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }

            @if (showBlock(riskBlockItem().preventionServices, ['consequential_diseases'])) {
                <big-service-tools-diabetes-diagnosis-card
                    [title]="t('card.consequentialDiseases.title')"
                    [link]="{ href: t('card.consequentialDiseases.link') }">
                    <p>{{ t('card.consequentialDiseases.description') }}</p>
                </big-service-tools-diabetes-diagnosis-card>
            }
        </div>
    }
</ng-container>
