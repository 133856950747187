@let bmi = bodyMassIndex();

<ng-container *transloco="let t; prefix: 'stDiabetesDiagnosis'">
    <h3>{{ t('bmiTable.title') }}</h3>

    <big-ui-alert
        type="info"
        class="mb-6"
        [classList]="'flex text-center border-2 rounded-md p-4 flex-col items-center dark:bg-dark-background'">
        <p class="mb-0">
            <strong>{{ t('bmiTable.yourBmiValue', { bmi: bmi | number: '1.0-2' : 'de-DE' }) }}</strong>
            {{ t('bmiTable.yourBmiValueClassification', { classification: t(getBmiClassification()) }) }}
        </p>
    </big-ui-alert>

    <p class="mb-6">{{ t('bmiTable.description') }}</p>

    <big-ui-table
        [headers]="bmitTableHeader"
        [customContent]="true">
        <tr
            bigUiTableRow
            [class]="{ '[&_>_td]:bg-primary-100!': bmi <= 18.5, '[&_>_td]:font-bold': bmi <= 18.5, 'dark:text-body': bmi <= 18.5 }">
            <td bigUiTableCell>{{ t('bmiTable.underWeight') }}</td>
            <td bigUiTableCell>{{ t('bmiTable.below', { value: '18,5' }) }}</td>
            <td bigUiTableCell>{{ t('bmiTable.riskOfDiseases.low') }}</td>
        </tr>
        <tr
            bigUiTableRow
            [class]="{
                '[&_>_td]:bg-primary-100!': bmi > 18.5 && bmi <= 24.9,
                '[&_>_td]:font-bold': bmi > 18.5 && bmi <= 24.9,
                'dark:text-body': bmi > 18.5 && bmi <= 24.9,
            }">
            <td bigUiTableCell>{{ t('bmiTable.normalWeight') }}</td>
            <td bigUiTableCell>18,5 - 24,9</td>
            <td bigUiTableCell>{{ t('bmiTable.riskOfDiseases.average') }}</td>
        </tr>
        <tr
            bigUiTableRow
            [class]="{
                '[&_>_td]:bg-primary-100!': bmi >= 25 && bmi <= 29.9,
                '[&_>_td]:font-bold': bmi >= 25 && bmi <= 29.9,
                'dark:text-body': bmi >= 25 && bmi <= 29.9,
            }">
            <td bigUiTableCell>{{ t('bmiTable.overWeight') }}</td>
            <td bigUiTableCell>25 - 29,9</td>
            <td bigUiTableCell>{{ t('bmiTable.riskOfDiseases.slightlyIncreased') }}</td>
        </tr>
        <tr
            bigUiTableRow
            [class]="{
                '[&_>_td]:bg-primary-100!': bmi >= 30 && bmi <= 34.9,
                '[&_>_td]:font-bold': bmi >= 30 && bmi <= 34.9,
                'dark:text-body': bmi >= 30 && bmi <= 34.9,
            }">
            <td bigUiTableCell>{{ t('bmiTable.gradeObesity1') }}</td>
            <td bigUiTableCell>30 - 34,9</td>
            <td bigUiTableCell>{{ t('bmiTable.riskOfDiseases.increased') }}</td>
        </tr>
        <tr
            bigUiTableRow
            [class]="{
                '[&_>_td]:bg-primary-100!': bmi >= 35 && bmi <= 39.9,
                '[&_>_td]:font-bold': bmi >= 35 && bmi <= 39.9,
                'dark:text-body': bmi >= 35 && bmi <= 39.9,
            }">
            <td bigUiTableCell>{{ t('bmiTable.gradeObesity2') }}</td>
            <td bigUiTableCell>35 - 39,9</td>
            <td bigUiTableCell>{{ t('bmiTable.riskOfDiseases.high') }}</td>
        </tr>
        <tr
            bigUiTableRow
            [class]="{ '[&_>_td]:bg-primary-100!': bmi > 40, '[&_>_td]:font-bold': bmi > 40, 'dark:text-body': bmi > 40 }">
            <td bigUiTableCell>{{ t('bmiTable.gradeObesity3') }}</td>
            <td bigUiTableCell>{{ t('bmiTable.from', { value: '40' }) }}</td>
            <td bigUiTableCell>{{ t('bmiTable.riskOfDiseases.veryHigh') }}</td>
        </tr>
    </big-ui-table>

    <p class="mt-6 mb-0 text-sm">
        <strong>
            {{ t('bmiTable.obesityHint') }}
        </strong>
    </p>
</ng-container>
